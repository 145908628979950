/*
*
* Sections
* --------------------------------------------------
*/


// Section Spacing
// -------------------------


// elements

.section-partners {
  padding: 15px 30px;
}

.section-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.section-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.section-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.section-elements-1 {
  padding-top: 70px;
  padding-bottom: 30px;
}

.section-custom-1 {
  padding-top: 70px;
  padding-bottom: 50px;
}

.section-custom-2 {
  padding-top: 50px;
  padding-bottom: 70px;
}

// pages

.section-xs,
.section-sm,
.section-md,
.section-lg,
.section-xlg {
  padding-top: 70px;
  padding-bottom: 70px;
}

.section-xl {
  padding-top: 90px;
  padding-bottom: 90px;
}

@media (min-width: $screen-sm-min) {

  // pages

  .section-partners {
    padding-left: 50px;
    padding-right: 50px;
  }

  .section-sm {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section-md {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .section-lg {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section-xlg {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .section-xl {
    padding-top: 154px;
    padding-bottom: 154px;
  }

  // elements
  .section-elements-1 {
    padding-top: 84px;
  }

  .section-sm-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  * {

    &.section-sm-0 {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

@media (min-width: $screen-md-min) {

  * {

    &.section-md-top-84 {
      padding-top: 84px;
    }

    &.section-md-bottom-30 {
      padding-bottom: 30px;
    }

    &.section-md-bottom-100 {
      padding-bottom: 100px;
    }

    &.section-md-top-120 {
      padding-top: 120px;
    }
  }

  .section-md-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .section-md-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media (min-width: $screen-xl-min) {

  .section-partners {
    padding-left: 0px;
    padding-right: 0px;
  }
}

* {
  &.section-bottom-0 {
    padding-bottom: 0;
  }

  &.section-top-0 {
    padding-top: 0;
  }

  &.section-md-bottom-50 {
    padding-bottom: 50px;
  }

  &.section-md-top-50 {
    padding-top: 50px;
  }

  &.section-bottom-100 {
    padding-bottom: 100px;
  }
}

// Section Styles
// -------------------------

.section-relative {
  position: relative;
  z-index: 1;
}

.index-2 {
  z-index: 2;
}

.index-3 {
  z-index: 3;
}

// Section Grid Demonstration
.section-grid-demonstration {
  @media (max-width: $screen-xs-min) {
	[class^="col"] {
	  padding: 5px;
	}
  }
  .grid-demonstration-item {
	background: $gray-lighter;

	@media (min-width: $screen-sm-min) {
	  padding: 35px 30px;
	  text-align: left;
	}

	@media (max-width: $screen-md-max) {
	  h3 {
		font-size: 16px;
		line-height: 16px;
	  }
	  p {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
	  }
	}
  }

  .divider + .row {
	margin-top: 35px;
  }
}