/*
*
* Circle Progress Bars
* --------------------------------------------------
*/


.progress-bar-circle {
  display: inline-block;
  position: relative;
  text-align: center;


  canvas {
    vertical-align: middle;
  }

  span {
    @include header-h2;
    
    line-height: 1;
    font-weight: 400;
    
    &::after {
      content: '%'
    }

    @media (min-width: $screen-sm-min) {
      line-height: 1;
    }
  }

  * + p { margin-top: 0; }
}

.progresss-bar-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  color: $gray-darker;
  font-family: $font-family-sec;
  transform: translate(-50%, -50%);
}

.progress-bar-circle-title {
  font-weight: 700;
  text-transform: lowercase;
}
