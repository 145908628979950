// Mixins

// Clearfix
@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// Disable padding - top on two similar backgrounds
@mixin bg-behaviour($bg-color) {
  background-color: $bg-color;

  & + & {
    padding-top: 0;
  }
}

// Link
//
@mixin link($default-clr, $hover-color) {
  &,
  &:active,
  &:focus {
    color: $default-clr;
  }

  &:hover {
    color: $hover-color;
  }
}

// Text variant
@mixin text-variant($parent, $color, $hover-color) {
  #{$parent} {
    color: $color;
  }
  a#{$parent}:hover,
  a#{$parent}:focus {
    color: $hover-color;
  }
}

// Reset default button styles
@mixin reset-button {
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  overflow: hidden;
  padding: 0;
  position: relative;
  //touch-action: manipulation;
  //user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  outline: none;

  &:active {
    cursor: wait;
  }
}