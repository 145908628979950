/*
* Static Layout
*/

.rd-navbar-static {
  display: block;

  .rd-navbar-inner{
    @include display-flex();
    @include align-items(center);
    @include justify-content(center);
  }

  .rd-navbar-top-panel{
    text-align: left;
  }

  .rd-navbar-nav{
    > li {

    }
  }
}

