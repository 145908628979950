/*
*
* Swiper
* --------------------------------------------------
*/


.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  /* Fix of Webkit flickering */
  z-index: 1;
  min-height: 250px;

  .shell-fluid {
    width: 100%;
  }
}

.swiper-bg-wrap {
  position: relative;
}

.swiper-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.swiper-bg-content {
  @extend %context-dark;

  position: relative;
  z-index: 2;
  height: inherit;
  min-height: inherit;
  color: $white;

  h2 > span {
    margin-right: 4px;
  }

  h3 {
    letter-spacing: .025em;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  h6 {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: .15em;
    text-transform: uppercase;
  }

  h3 + h2 { margin-top: 20px; }
  h6 + h2 { margin-top: 22px; }
  * + .group-xs { margin-top: 28px; }

  @media (min-width: $screen-sm-min) {
    h6 {
      margin-left: 0;
    }

    h2 > span {
      margin-right: 10px;
    }
  }
}

.form-creative {

  h2 {
    > span {
      margin-right: 4px;
    }
  }

  h4 {
    line-height: 1.3;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }

  .offset-bottom-0 { margin-bottom: 0; }

  * + .button { margin-top: 28px; }

  h4 + h2 { margin-top: 16px; }

  @media (min-width: $screen-sm-min) {

    h2 {

      > span {
        margin-right: 10px;
      }
    }

    h4 {
      margin-left: 0;
    }
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    h2 {
      font-size: 36px;

      span {
        font-size: 34px;
      }
    }
  }
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  @include flex-direction(column);
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  @include display-flex();
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  touch-action: pan-x;
}


/* Coverflow */
.swiper-container-coverflow .swiper-wrapper {
  /* Windows 8 IE 10 fix */
}


// Swiper fade effect
//--------------------------------------------------

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}


// Swiper preloader
//--------------------------------------------------

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}

.swiper-slide > .vide__body,
.swiper-slide > .parallax_cnt {
  height: 100%;
}


// Swiper navigation
//--------------------------------------------------

.swiper-button-prev,
.swiper-button-next {
  position: relative;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  will-change: transform;
  z-index: 2;

  &:before,
  &:after {
    display: inline-block;
    vertical-align: middle;
    background: $white;
    transition: inherit;
  }

  // Disabled button
  &.swiper-button-disabled{
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
}

// Swiper pagination
//--------------------------------------------------

.swiper-pagination {

  &.swiper-pagination-hidden {
    opacity: 0;
  }

  &.swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
  }
}

// Dark pagination
.swiper-pagination-black{
  .swiper-pagination-bullet {
    background: rgba(0,0,0,.6);

    &.swiper-pagination-bullet-active {
      background: rgba(0,0,0,1);
    }
  }
}


// Swiper pagination orientation
//--------------------------------------------------

// Vertical pagination
.swiper-container-vertical{
  > .swiper-pagination {
    right: 10px;
    top: 50%;
    transform: translate3d(0px, -50%, 0);

    .swiper-pagination-bullet {
      margin: 5px 0;
      display: block;
    }
  }
}

// Horizontal pagination
.swiper-container-horizontal{
  > .swiper-pagination {
    bottom: 20px;
    left: 0;
    width: 100%;

    .swiper-pagination-bullet {
      margin: 0 5px;
    }
  }
}


// Swiper slide styles
//--------------------------------------------------

.swiper-slide {
  position: relative;

  @include display-flex();
  @include align-items(center);
  @include justify-content(center);
  @include flex-shrink(0);
  width: 100%;
  height: 100%;

  white-space: nowrap;

  @extend %context-dark;

  &-caption {
    width: 100%;
    white-space: normal;
  }
}

.swiper-custom-container { position: relative; }

// Swiper-Classic

.swiper-classic {

  .swiper-slide-caption {
    min-height: 300px;

    @media (min-width: $screen-sm-min) {
      min-height: 500px;
    }

    @media (min-width: $screen-md-min) {
      min-height: 650px;
    }

    @media (min-width: $screen-lg-min) {
      min-height: 764px;
    }
  }

  .swiper-slide-content {
    width: 100%;
    padding-top: 80px;
    padding-bottom: 100px;

    @media (min-width: $screen-md-min) {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    @media (min-width: $screen-lg-min) {
      padding-top: 150px;
      padding-bottom: 150px;
    }
  }

  .desktop & {
    .swiper-slide-content {
      @media (min-width: $screen-md-min) {
        padding-top: 200px;
      }

      @media (min-width: $screen-lg-min) {
        padding-top: 265px;
      }
    }
  }
}


// Swiper-Modern

.swiper-modern {

  .swiper-slide-caption {
    min-height: 420px;

    @media (min-width: $screen-sm-min) {
      min-height: 500px
    }

    @media (min-width: $screen-md-min) {
      min-height: 550px
    }

    @media (min-width: $screen-lg-min) {
      min-height: 685px
    }
  }

  .swiper-slide-content {
    width: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
    
    @media (min-width: $screen-md-min) {
      padding-top: 60px;
      padding-bottom: 120px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    display: none;
    top: 44%;
    color: $white;
    white-space: nowrap;
    transform: translateY(-50%);

    .icon-arrow {
      font-size: 60px;
      vertical-align: middle;
      max-height: 86px;
    }
    
    &:hover {
      
      &:after {
        width: 114px;
      }

      &:before {
        width: 114px;
      }
    }

    @media (min-width: $screen-md-min) {
      display: block;
    }
  }

  .swiper-button-prev {
    left: calc(50% - 450px);

    &:after {
      content: '';
      width: 0;
      height: 5px;
      margin-left: 4px;
    }
  }

  .swiper-button-next {
    right: calc(50% - 450px);
    transform: translateY(-50%);

    &:before {
      content: '';
      width: 0;
      height: 5px;
      margin-right: 4px;
    }

    > .icon-arrow {
      transform: scale(-1, 1)
    }
  }

  .swiper-pagination {
    position: absolute;
    left: 0;
    bottom: 30px;
    width: 100%;

    display: block;
    text-align: center;
    transition: .3s;
    transform: translate3d(0, 0, 0);
    z-index: 10;

    .swiper-pagination-bullet {
      width: 14px;
      height: 14px;
      margin: 0 15px;
      display: inline-block;
      background: transparent;
      border: 2px solid $white;
      transition: .2s;
      transform: rotate(45deg);
      will-change: transform;

      &.swiper-pagination-bullet-active,
      &:hover{
        background: rgba($white,1);
      }
    }
  }


  @media (min-width: $screen-md-min) {

    .swiper-pagination {
      display: none;
    }
  }

  @media (min-width: $screen-lg-min) {
    .swiper-button-prev {
      left: calc(50% - 500px);
    }

    .swiper-button-next {
      right: calc(50% - 500px);
    }
  }

  @media (min-width: $screen-xlg-min) {
    .swiper-button-prev {
      left: calc(50% - 600px);
    }

    .swiper-button-next {
      right: calc(50% - 600px);
    }
  }
}

.swiper-custom-nav {

  .box-nav {
    position: absolute;
    left: 50%;
    line-height: 1;
    font-family: $font-family-sec;
    z-index: 2;

    > * {
      display: inline-block;
      margin: 0 3px;
    }
  }

  .box-nav-small {
    font-size: 24px;

    .swiper-pagination {
      width: 32px;
      margin-right: -4px;
    }

    .total-counter {
      font-size: 14px;
      margin-left: -3px;
    }

    .swiper-button-prev,
    .swiper-button-next {

      &:after {
        width: 0;
        height: 2px;
        content: '';
      }

      &:hover {
        &:after {
          width: 53px;
        }
      }
    }
  }

  .box-nav-large {
    font-size: 48px;

    .swiper-pagination {
      width: 64px;
      margin-right: -14px;

      .swiper-pagination-bullet {

        &-active {
          color: $white;
        }
      }
    }

    .total-counter {
      font-size: 24px;
      margin-left: -14px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      top: -2px;
      height: 48px;
      padding-left: 15px;

      &:before {
        top: 58%;
        right: 34px;
        height: 3px;
        width: 25px;
      }
    }
  }

  .swiper-pagination {
    position: relative;
    counter-reset: span;

    .swiper-pagination-bullet {
      position: absolute;
      overflow: hidden;
      pointer-events: none;
      opacity: 0;
      left: 0;

      &-active {
        position: relative;
        overflow: visible;
        opacity: 1;
      }

      &:before {
        line-height: 1;
        letter-spacing: .025em;
        content: counter(span, decimal-leading-zero);
        counter-increment: span;
      }
    }
  }

  .swiper-button-next {
    transform: scale(-1, 1);
  }

  .wrap-counter {
    color: $white;
  }

  .wrap-counter {
    > * {
      display: inline-block;
    }
  }

  .icon-arrow {
    font-size: 24px;
    line-height: 30px;
    color: $white;
    vertical-align: middle;
    max-height: 30px;
  }
}

// Swiper-Creative

.swiper-creative {

  .swiper-container {

    &:after {
      position: absolute;
      content: ' ';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba($gray-base, .6s);
      z-index: 1;

      @media (min-width: $screen-lg-min) {
        min-width: 860px;
        left: auto;
        background: rgba($gray-base, .7);
      }

      @media (min-width: $screen-xl-min) {
        min-width: 951px;
      }
    }
  }

  .swiper-slide-caption {
    min-height: 420px;

    @media (min-width: $screen-sm-min) {
      min-height: 500px
    }

    @media (min-width: $screen-md-min) {
      min-height: 650px
    }

    @media (min-width: $screen-lg-min) {
      min-height: 790px
    }
  }

  .swiper-slide-content {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;

    @media (min-width: $screen-md-min) {
      padding-top: 150px;
    }

    @media (min-width: $screen-lg-min) {
      padding-top: 130px;
    }
  }

  h1 {
    @media (min-width: $screen-lg-min) {
      max-width: 700px;
    }

    @media (min-width: $screen-xl-min) {
      max-width: 100%;
    }
  }

  .box-right {

    @media (min-width: $screen-lg-min) {
      max-width: 710px;
      margin-left: auto;
    }

    @media (min-width: $screen-xl-min) {
      max-width: 762px;
    }
  }

  .box-nav {
    bottom: 40px;
    width: 100%;
    text-align: center;
    transform: translateX(-50%);

    @media (min-width: $screen-lg-min) {
      right: 0;
      left: auto;
      max-width: 787px;
      text-align: left;
      transform: translateX(0);
    }

    @media (min-width: $screen-md-min) {
      bottom: 50px;
    }

    @media (min-width: $screen-xl-min) {
      max-width: 837px;
    }
  }
}

.box-nav__classic {
  width: 100%;
  transform: translateX(-50%);
  bottom: 35px;

  @media (min-width: $screen-md-min) {
    bottom: 50px;
  }

  @media (min-width: $screen-xl-min) {
    bottom: 100px;
  }
}

.box-nav__creative {
  bottom: 50%;
  width: 100%;
  transform: translate(-50%, 50%) scale(.7);

  @media (min-width: $screen-sm-min) {
    transform: translate(-50%, 50%) scale(1);
    width: auto;
  }

  @media (min-width: $screen-lg-min) {
    transform: translate(-50%, 100%);
  }
}

.swiper-slide-caption {
  @include display-flex();
  @include flex-direction(column);
  @include flex-wrap(nowrap);
  @include align-items(center);
  @include justify-content(center);
}

.swiper-carousel {

  .swiper-slide-caption {

    @include justify-content(flex-end);
    min-height: 100%;
    padding-top: 30px;
    padding-bottom: 80px;

    @media (min-width: $screen-xl-min) {
      padding-bottom: 130px;
    }
  }

  .swiper-slide { min-height: 100%; }

  .swiper-slide-content {
    text-align: center;
    max-width: 90%;

    @media (min-width: $screen-xl-min) {
      max-width: 75%;
    }
  }

  p.text-divider  {
    opacity: 1;
    min-height: 133px;
    max-height: 133px;

    span {
      color: rgba($white, .5);
    }

    @media (min-width: $screen-xs-min) {
      min-height: 95px;
      max-height: 95px;
    }

    @media (min-width: $screen-sm-min) {
      min-height: 133px;
      max-height: 133px;
    }

    @media (min-width: $screen-md-min) {
      min-height: 95px;
      max-height: 95px;
    }

    @media (min-width: $screen-lg-min) {
      min-height: 114px;
      max-height: 114px;
    }

    @media (min-width: $screen-xl-min) {
      min-height: auto;
      max-height: 135px;
    }
  }

  .range-custom > div:nth-child(2) {

    @media (min-width: $screen-xs-min) {
      order: 1;
    }

    @media (min-width: $screen-sm-min) {
      order: 0;
    }
  }
}

.swiper-carousel-classic {

  .swiper-slide-caption {
    
    @media (min-width: $screen-lg-min) {
      min-height: 400px;
    }
  }

  img {

    @media (min-width: $screen-sm-min) {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      max-width: none;
    }
  }
}

@supports (object-fit: cover) {

  .swiper-carousel-classic {
    img {
      @media (min-width: $screen-sm-min) {
        width: 100%;
        height: 100%;
        object-fit: cover;
        -o-object-fit: cover;
        object-position: 50% 50%;
        -o-object-position: 50% 50%;
        left: auto;
        top: auto;
        transform: translate(0, 0);
        min-width: auto;
        min-height: auto;  
      }
    }
  }
}

.swiper-carousel-creative {
  .swiper-slide-active {
    z-index: 1;
  }
}

.ie-10,
.ie-11 {

  // Swiper-Classic

  .swiper-classic {
    .swiper-slide-caption {
      height: 300px;

      @media (min-width: $screen-sm-min) {
        height: 540px;
      }

      @media (min-width: $screen-md-min) {
        height: 650px;
      }

      @media (min-width: $screen-lg-min) {
        height: 784px;
      }
    }
  }

  // Swiper-Modern

  .swiper-modern {

    .swiper-slide-caption {
      height: 420px;

      @media (min-width: $screen-sm-min) {
        height: 500px
      }

      @media (min-width: $screen-md-min) {
        height: 600px
      }

      @media (min-width: $screen-lg-min) {
        height: 738px
      }
    }
  }

  // Swiper-Creative

  .swiper-creative {

    .swiper-slide-caption {
      height: 420px;

      @media (min-width: $screen-sm-min) {
        height: 500px
      }

      @media (min-width: $screen-md-min) {
        height: 650px
      }

      @media (min-width: $screen-lg-min) {
        height: 790px
      }
    }
  }

  .swiper-carousel .swiper-slide-caption { height: 396px; }
}

.swiper-scale-effect .swiper-slide-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 8.5s ease-out;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1); }

.swiper-scale-effect .swiper-slide:nth-child(1) .swiper-slide-cover {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0; }

.swiper-scale-effect .swiper-slide:nth-child(2) .swiper-slide-cover {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%; }

.swiper-scale-effect .swiper-slide:nth-child(3) .swiper-slide-cover {
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

.swiper-scale-effect .swiper-slide.swiper-slide-active .swiper-slide-cover {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);  }

.swiper-scale-effect .swiper-slide:nth-child(2).swiper-slide-active .swiper-slide-cover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1); }