/*
*
* Page Loaders
* --------------------------------------------------
*/


// Base styles
// -------------------------

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 9999999;
  background: #fff;
  transition: .3s all ease;

  &.loaded {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }

  &.ending {
    display: none;
  }

  .preloader-body {
    text-align: center;
  }
}

// Custom Styles
// -------------------------

.loader {
  height: 58px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  width: 58px;
}
.loader span {
  background: $primary;
  display: block;
  height: 12px;
  opacity: 0;
  position: absolute;
  width: 12px;
  animation: load 4.6s ease-in-out infinite;
  -o-animation: load 4.6s ease-in-out infinite;
  -ms-animation: load 4.6s ease-in-out infinite;
  -webkit-animation: load 4.6s ease-in-out infinite;
  -moz-animation: load 4.6s ease-in-out infinite;
}
.loader span.block-1 {
  animation-delay: 1.06s;
  -o-animation-delay: 1.06s;
  -ms-animation-delay: 1.06s;
  -webkit-animation-delay: 1.06s;
  -moz-animation-delay: 1.06s;
  left: 0px;
  top: 0px;
}
.loader span.block-2 {
  animation-delay: 0.97s;
  -o-animation-delay: 0.97s;
  -ms-animation-delay: 0.97s;
  -webkit-animation-delay: 0.97s;
  -moz-animation-delay: 0.97s;
  left: 16px;
  top: 0px;
}
.loader span.block-3 {
  animation-delay: 0.87s;
  -o-animation-delay: 0.87s;
  -ms-animation-delay: 0.87s;
  -webkit-animation-delay: 0.87s;
  -moz-animation-delay: 0.87s;
  left: 31px;
  top: 0px;
}
.loader span.block-4 {
  animation-delay: 0.78s;
  -o-animation-delay: 0.78s;
  -ms-animation-delay: 0.78s;
  -webkit-animation-delay: 0.78s;
  -moz-animation-delay: 0.78s;
  left: 47px;
  top: 0px;
}
.loader span.block-5 {
  animation-delay: 0.69s;
  -o-animation-delay: 0.69s;
  -ms-animation-delay: 0.69s;
  -webkit-animation-delay: 0.69s;
  -moz-animation-delay: 0.69s;
  left: 0px;
  top: 16px;
}
.loader span.block-6 {
  animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  left: 16px;
  top: 16px;
}
.loader span.block-7 {
  animation-delay: 0.51s;
  -o-animation-delay: 0.51s;
  -ms-animation-delay: 0.51s;
  -webkit-animation-delay: 0.51s;
  -moz-animation-delay: 0.51s;
  left: 31px;
  top: 16px;
}
.loader span.block-8 {
  animation-delay: 0.41s;
  -o-animation-delay: 0.41s;
  -ms-animation-delay: 0.41s;
  -webkit-animation-delay: 0.41s;
  -moz-animation-delay: 0.41s;
  left: 47px;
  top: 16px;
}
.loader span.block-9 {
  animation-delay: 0.32s;
  -o-animation-delay: 0.32s;
  -ms-animation-delay: 0.32s;
  -webkit-animation-delay: 0.32s;
  -moz-animation-delay: 0.32s;
  left: 0px;
  top: 31px;
}
.loader span.block-10 {
  animation-delay: 0.23s;
  -o-animation-delay: 0.23s;
  -ms-animation-delay: 0.23s;
  -webkit-animation-delay: 0.23s;
  -moz-animation-delay: 0.23s;
  left: 16px;
  top: 31px;
}
.loader span.block-11 {
  animation-delay: 0.14s;
  -o-animation-delay: 0.14s;
  -ms-animation-delay: 0.14s;
  -webkit-animation-delay: 0.14s;
  -moz-animation-delay: 0.14s;
  left: 31px;
  top: 31px;
}
.loader span.block-12 {
  animation-delay: 0.05s;
  -o-animation-delay: 0.05s;
  -ms-animation-delay: 0.05s;
  -webkit-animation-delay: 0.05s;
  -moz-animation-delay: 0.05s;
  left: 47px;
  top: 31px;
}
.loader span.block-13 {
  animation-delay: -0.05s;
  -o-animation-delay: -0.05s;
  -ms-animation-delay: -0.05s;
  -webkit-animation-delay: -0.05s;
  -moz-animation-delay: -0.05s;
  left: 0px;
  top: 47px;
}
.loader span.block-14 {
  animation-delay: -0.14s;
  -o-animation-delay: -0.14s;
  -ms-animation-delay: -0.14s;
  -webkit-animation-delay: -0.14s;
  -moz-animation-delay: -0.14s;
  left: 16px;
  top: 47px;
}
.loader span.block-15 {
  animation-delay: -0.23s;
  -o-animation-delay: -0.23s;
  -ms-animation-delay: -0.23s;
  -webkit-animation-delay: -0.23s;
  -moz-animation-delay: -0.23s;
  left: 31px;
  top: 47px;
}
.loader span.block-16 {
  animation-delay: -0.32s;
  -o-animation-delay: -0.32s;
  -ms-animation-delay: -0.32s;
  -webkit-animation-delay: -0.32s;
  -moz-animation-delay: -0.32s;
  left: 47px;
  top: 47px;
}




@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(-97px);
  }
  15% {
    opacity: 0;
    transform: translateY(-97px);
  }
  30% {
    opacity: 1;
    transform: translateY(0);
  }
  70% {
    opacity: 1;
    transform: translateY(0);
  }
  85% {
    opacity: 0;
    transform: translateY(97px);
  }
  100% {
    opacity: 0;
    transform: translateY(97px);
  }
}

@-o-keyframes load {
  0% {
    opacity: 0;
    -o-transform: translateY(-97px);
  }
  15% {
    opacity: 0;
    -o-transform: translateY(-97px);
  }
  30% {
    opacity: 1;
    -o-transform: translateY(0);
  }
  70% {
    opacity: 1;
    -o-transform: translateY(0);
  }
  85% {
    opacity: 0;
    -o-transform: translateY(97px);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(97px);
  }
}

@-ms-keyframes load {
  0% {
    opacity: 0;
    -ms-transform: translateY(-97px);
  }
  15% {
    opacity: 0;
    -ms-transform: translateY(-97px);
  }
  30% {
    opacity: 1;
    -ms-transform: translateY(0);
  }
  70% {
    opacity: 1;
    -ms-transform: translateY(0);
  }
  85% {
    opacity: 0;
    -ms-transform: translateY(97px);
  }
  100% {
    opacity: 0;
    -ms-transform: translateY(97px);
  }
}

@-webkit-keyframes load {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-97px);
  }
  15% {
    opacity: 0;
    -webkit-transform: translateY(-97px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  70% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  85% {
    opacity: 0;
    -webkit-transform: translateY(97px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(97px);
  }
}

@-moz-keyframes load {
  0% {
    opacity: 0;
    -moz-transform: translateY(-97px);
  }
  15% {
    opacity: 0;
    -moz-transform: translateY(-97px);
  }
  30% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  70% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  85% {
    opacity: 0;
    -moz-transform: translateY(97px);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(97px);
  }
}