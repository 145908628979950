// grid 18 columns
// --------------------------------------------------
@mixin make-cell-2($resolution, $count: 18) {
  .cell-#{$resolution}-preffix-0 {
    margin-left: 0%;
  }

  @for $i from 1 through $count {
    .cell-#{$resolution}-#{$i} {
      @include flex-basis(percentage($i / $count));
    }

    .cell-#{$resolution}-preffix-#{$i} {
      margin-left: percentage($i / $count);
    }
  }

  .cell-#{$resolution}-1-5 {
    @include flex-basis(percentage(0.2));
  }
}


@mixin make-fallback-2($resolution, $count: 18) {
  @for $i from 1 through $count {
    .cell-xs-#{$i}{
      margin-left: auto;
      margin-right: auto;
      max-width: percentage($i / $count);
    }
  }

  .cell-xs-1-5{
    margin-left: auto;
    margin-right: auto;
    max-width: percentage(0.2);
  }

  @for $i from 1 through $count {
    .cell-sm-#{$i}{
      margin-left: auto;
      margin-right: auto;
      max-width: percentage($i / $count);
    }
  }

  .cell-sm-1-5{
    margin-left: auto;
    margin-right: auto;
    max-width: percentage(0.2);
  }

  @for $i from 1 through $count {
    .cell-md-#{$i}{
      margin-left: auto;
      margin-right: auto;
      max-width: percentage($i / $count);
    }
  }

  .cell-md-1-5{
    margin-left: auto;
    margin-right: auto;
    max-width: percentage(0.2);
  }

  @for $i from 1 through $count {
    .cell-lg-#{$i} {
      margin-left: auto;
      margin-right: auto;
      max-width: percentage($i / $count);
    }
  }

  .cell-lg-1-5{
    margin-left: auto;
    margin-right: auto;
    max-width: percentage(0.2);
  }

  [class*="cell-xs-preffix-"],
  [class*="cell-sm-preffix-"],
  [class*="cell-md-preffix-"],
  [class*="cell-lg-preffix-"]{
    margin-left: auto;
  }
}



@mixin make-grid-system-2($resolution, $cell-count-2) {
  .range-#{$resolution}-center {
    @include justify-content(center);
  }

  .range-#{$resolution}-left {
    @include justify-content(flex-start);
  }

  .range-#{$resolution}-right {
    @include justify-content(flex-end);
  }

  .range-#{$resolution}-justify {
    @include justify-content(space-between);
  }

  .range-#{$resolution}-around {
    @include justify-content(space-around);
  }

  .range-#{$resolution}-top {
    @include align-items(flex-start);
  }

  .range-#{$resolution} {
    @include flex-direction(row);
  }

  .range-#{$resolution}-reverse {
    @include flex-direction(row-reverse);
  }

  .range-#{$resolution}-middle {
    @include align-items(center);
  }

  .range-#{$resolution}-bottom {
    @include align-items(flex-end);
  }

  .cell-#{$resolution}-top {
    @include align-self(flex-start);
  }

  .cell-#{$resolution}-middle {
    @include align-self(center);
  }

  .cell-#{$resolution}-bottom {
    @include align-self(flex-end);
  }

  .range-custom > {
    @include make-cell-2($resolution, $cell-count-2);
  }
}

