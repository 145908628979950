/*
*
* Thumbnails
* --------------------------------------------------
*/


.thumbnail-type-1{

}


// Thumb
//

.thumb {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
  width: 100%;
  min-width: 100%;
  max-height: 100%;
  background: $primary;
  text-align: center;
  cursor: pointer;

  img {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    width: 100%;
    opacity: 1;
  }

  .thumb__title {
    word-spacing: -0.15em;
    font-weight: 300;
  }

  p {
    letter-spacing: 1px;
    font-size: 14px;
  }

  .thumb__title,
  p {
    margin: 0;
  }

  .thumb__overlay {
    padding: 40px;
    color: $white;
    text-transform: uppercase;
    font-size: 20px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::before,
    &::after {
      pointer-events: none;
    }

    > a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    > a {
      z-index: 1000;
      text-indent: 200%;
      white-space: nowrap;
      font-size: 0;
      opacity: 0;
    }
  }
}

.thumb.thumb--effect-layla {
  background: $primary;

  .thumb__overlay {
    padding: 3em;

    &::before,
    &::after {
      position: absolute;
      content: '';
      opacity: 0;
      transition: 0.3s;
    }

    &::before {
      top: 40px;
      right: 20px;
      bottom: 40px;
      left: 20px;
      border-top: 1px solid $white;
      border-bottom: 1px solid $white;
      transform: scale(0,1);
      transform-origin: 0 0;

      @media (min-width: $screen-sm-min) {
        top: 50px;
        right: 30px;
        bottom: 50px;
        left: 30px;
      }
    }

    &::after {
      top: 20px;
      right: 40px;
      bottom: 20px;
      left: 40px;
      border-right: 1px solid $white;
      border-left: 1px solid $white;
      transform: scale(1,0);
      transform-origin: 100% 0;

      @media (min-width: $screen-sm-min) {
        top: 30px;
        right: 50px;
        bottom: 30px;
        left: 50px;
      }
    }
  }

  .thumb__title {
    padding-top: 26%;
    transition: transform 0.35s;
    color: $white;
  }

  p {
    padding: 0.5em 2em;
    text-transform: none;
    opacity: 0;
    transform: translate3d(0,-10px,0);
  }

  img,
  .thumb__title {
    transform: translate3d(0,-30px,0) scale(1.17);
    transform-origin: 50% 0;
  }

  img,
  &::before,
  &::after,
  p {
    transition: opacity 0.35s, transform 0.35s;
  }
}

.desktop {

  @media (min-width: $screen-md-min) {
    .thumb.thumb--effect-layla {

      &:hover{
        img {
          opacity: 0.7;
          transform: translate3d(0,0,0) scale(1.17);
        }
        .thumb__overlay::before,
        .thumb__overlay::after {
          opacity: 1;
          transform: scale(1);
        }

        .thumb__title,
        p {
          opacity: 1;
          transform: translate3d(0,0,0);
        }
      }
    }
  }
}