/*
*
* Navigation
* --------------------------------------------------
*/

.navigation{

}


.navigation-pills{
  li{
    a{
      display: inline-block;

      padding: 3px 9px;
      border: 1px solid $primary;
      border-radius: 5px;

      font-size: 16px;
      transition: .2s;

      &.active,
      &:hover,
      &:focus{
        background-color: $primary;
        color: $white;
      }
    }
  }
}

.nav-custom{
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;

  a {
    display: block;
    min-width: 122px;
    color: $white;
    font-family: $font-family-sec;
    letter-spacing: .025em;
    padding: 8px 19px;
    transition: .3s;
    background: $primary;

    @media (min-width: $screen-md-min) {
      padding: 13px 18px;
    }

    &:hover{
      background-color: $gray-light;
    }

    &.active{
      background-color: $gray-light;
    }
  }
}