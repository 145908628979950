/*
*
* RD Google Map
* --------------------------------------------------
*/

$map-height: 200px;
$map-xs-height: 300px;
$map-md-height: 430px;

.rd-google-map__model {
  color: $gray-base;
  height: $map-height;

  img {
    max-width: none !important;
  }
}

.map_locations {
  display: none;
}

.rd-google-map__model {
  @media (min-width: $screen-xs-min) {
    height: $map-xs-height;
  }

  @media (min-width: $screen-md-min) {
    height: $map-md-height;
  }
}

.footer-gmap {
  $map-md-height: 390px;

  .rd-google-map__model {
    @media (min-width: $screen-xs-min) {
      height: $map-xs-height;
    }

    @media (min-width: $screen-md-min) {
      height: $map-md-height;
    }
  }
}