/*
*
* Element groups
* --------------------------------------------------
*/

html .page {
  .group-flickr {
    @include group(4px, 4px);
  }

  .group-button-blog {
    @include group(2px, 4px);
  }

  .group-tabs {
    @include group(10px, 9px);
  }

  .group-post-meta {
    @include group(12px, 12px);
  }

  .group-xs {
    @include group(12px, 16px);
  }

  .group-sm {
    @include group(18px, 10px);
  }

  .group-lg {
    @include group(20px, 10px);
  }

  .group-xl {
    @include group(27px, 27px);
  }

  .group-top {
    > *, > *:first-child {
      vertical-align: top;
    }
  }

  .group-end {
    @include display-flex();
    @include flex-wrap(wrap);
    @include align-items(flex-end);
  }

  .group-horizontal-center {
    @include justify-content(center)
  }

  .group-middle {
    @include display-flex();
    @include flex-direction(row);
    @include flex-wrap(wrap);
    @include align-items(center);
  }

  .group-bottom {
    > *, > *:first-child {
      vertical-align: bottom;
    }
  }
}

.group-between {
  @include display-flex();
  @include flex-direction(column);

  @media (min-width: $screen-sm-min) {
    @include flex-direction(row);
    @include justify-content(space-between);
    @include align-items(center);
  }


  * + .rd-mailform {
    margin-top: 20px;

    @media (min-width: $screen-sm-min) {
      margin-top: 0;
      margin-left: 10px;
    }
  }

  .form-wrap + .form-button {
    margin-top: 10px;

    @media (min-width: $screen-xs-min) {
      margin-top: 0;
      margin-left: 10px;
    }
  }

  .rd-mailform-inline {
    @include flex-direction(column);
    width: 100%;

    @media (min-width: $screen-xs-min) {
      @include flex-direction(row);
    }
  }

  .button {
    @media (min-width: $screen-xs-min) {
      margin-left: 0;
    }
  }
}

.group-post-comment {
  @include display-flex();
  @include flex-direction(column);
  @include align-items(center);

  @media (min-width: $screen-xs-min) {
    @include justify-content(space-between);
    @include flex-direction(row);
    @include align-items(flex-start);
  }
}