//
// Grid modules
// --------------------------------------------------
.row-no-gutter {
  margin-left: 0;
  margin-right: 0;

  > [class*='col'] {
    padding-left: 0;
    padding-right: 0;
  }
}

.row-lg-5-column {

  @media (min-width: $screen-lg-min) {
    > [class*='col'] {
      width: 20%;
    }
  }
}

.row-gutter-custom {
  @media (max-width: $screen-md-max) {
    margin-left: -8px;
    margin-right: -8px;

    > [class*='col'] {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

// Grid demonstration
//
.grid-demonstration {
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;

  h3 + .row {
    margin-top: 30px;
  }

  @media (max-width: $screen-md-max) {
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.container-fullwidth.grid-demonstration {

  * + p {
    margin-top: 12px;
  }

  [class*='col'] {
    padding: 15px 10px;

    @media (min-width: $screen-sm-min) {
      padding: 20px 30px;
    }

    @media (min-width: $screen-lg-min) {
      padding: 20px 30px 40px;
    }
  }

  .row {
    @media (min-width: 1400px) {
      padding-right: 50px;
      padding-left: 50px;
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}

.container.grid-demonstration {
  * + p {
    margin-top: 12px;
  }
}

.grid-system-bordered {
  [class*='col'] {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .row {
    border-top: 1px solid $gray-7;
  }

  @media (min-width: $screen-md-min) {
    [class*='col'] {
      padding-bottom: 20px;
    }
  }

  @media (min-width: $screen-lg-min) {
    [class*='col'] {
      padding-top: 20px;
      padding-bottom: 40px;
    }
  }
}

.grid-system-outline {
  .row {
    border: 1px solid $gray-7;
    border-width: 1px 1px 0 1px;

    &:last-child {
      border-bottom-width: 1px;
    }
  }

  [class*='col']:not(:last-child) {
    border-right: 1px solid $gray-7;
  }

  .row + .row {
    margin-top: 0;
  }
}

// Range bordered
//
.range-custom-bordered {
  [class*='cell'] {
    border-style: solid;
    border-color: #e8e8e8;
    border-width: 0;
  }

  [class*='cell']:nth-child(n + 2) {
    border-width: 1px 0 0 0;
  }

  @media (min-width: $screen-xs-min) {
    .cell-xs-6 {
      &:nth-child(odd) {
        border-width: 0 1px 1px 0;
      }

      &:nth-child(even) {
        border-width: 0 0 1px 0;
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    .cell-sm-3 {
      &:first-child {
        border-width: 0 1px 0 1px;
      }

      &:nth-child(n + 2) {
        border-width: 0 1px 0 0;
      }
    }

    .cell-sm-4 {
      &:nth-child(n) {
        border-width: 0;
      }

      &:nth-child(n + 2) {
        border-width: 0 0 0 1px;
      }
    }
  }
}

* + .container-fullwidth { margin-top: 30px; }