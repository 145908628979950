/*
*
* Responsive unit
* --------------------------------------------------
*/


@include unit-responsive($medias, 20px, 30px);

// Unit Spacing
//

.unit-spacing-icon {
  @include unit-spacing($medias, 10px, 12px);
}

.unit-spacing-icon-2 {
  @include unit-spacing($medias, 10px, 24px);
}

.unit-spacing-icon-3 {
  @include unit-spacing($medias, 10px, 4px);
}

.unit-spacing-grid {
  @include unit-spacing($medias, 25px, 30px);
}

.unit-spacing-xs {
  @include unit-spacing($medias, 7px, 10px);
}

.unit-spacing-sm {
  @include unit-spacing($medias, 10px, 15px);
}

.unit-spacing-md {
  @include unit-spacing($medias, 7px, 15px);
}

.unit-spacing-lg {
  @include unit-spacing($medias, 10px, 20px);
}

.unit-box-comment {
  @include unit-spacing($medias, 10px, 60px);
}

.unit-spacing-vertical-0  {
  @include unit-spacing($medias, 0px, 15px);
}

.inset-sm-inverse-25.unit {
  @media (max-width: $screen-xs-max) {
    > .unit__body {
      padding-top: 25px;
    }

    > .unit__body + .unit__right {
      padding-top: 0;
    }
  }
}

.unit {
  img {
    width: 100%;
  }
}

.unit-sm-row,
.unit-sm-row.unit-sm-horizontal {
  @media (min-width: $screen-sm-min) {
    @include flex-direction(row);
  }
}
