/*
*
* Owl Carousel
* --------------------------------------------------
*/

.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;

  .owl-item > .owl-item { width: 100%; }
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  font: 400 40px/80px 'FontAwesome';
  cursor: pointer;
  z-index: 1;
  transition: scale 100ms ease;
  &:before {
    content: '\f144';
  }
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

/*
 * Owl Navigation
 */

.owl-nav {
  display: none;

  @media (min-width: $screen-md-min) {
    display: block;
  }
}

.owl-prev, .owl-next {
  position: absolute;
  color: $gray;
  font: 400 24px/50px 'Material Icons';
  transition: 200ms ease-in-out;

  &:before {
    content: '\e317';
  }
}

.owl-prev {
  left: 0;
  transform: translateY(-50%);
}

.owl-next {
  right: 0;
  transform: scale(-1, 1) translateY(-50%);
}

/*
 * Owl Pagination
 */

.owl-dots {
  text-align: center;
  margin-top: 30px;
  display: block;
}

.owl-dot {
  width: 14px;
  height: 14px;
  margin: 0 15px;
  display: inline-block;
  background: transparent;
  border: 2px solid $primary;
  transition: .2s;
  transform: rotate(45deg);
  will-change: transform;

  &:hover,
  &:focus {
    background: rgba($primary,1);
  }

  &.active {
    background: rgba($primary,1);
  }

  & + &{
    margin-left: 10px;
  }
}

.data-dots-custom {

  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  transition: .3s;
  z-index: 10;
  
  @media (min-width: $screen-md-min) {
    display: none;
  }

  > div {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 15px;
    background: transparent;
    transition: .2s;
    transform: rotate(45deg);
    will-change: transform;
  }
}

.dots-classic {
  bottom: 35px;

  > div {
    border: 2px solid $white;

    &.active,
    &:hover{
      background: $white;
    }
  }
}

.dots-modern {
  bottom: -45px;

  > div {
    border: 2px solid $gray-light;

    &.active,
    &:hover{
      background: $gray-light;
    }
  }
}

.dots-primary {
  bottom: -60px;

  > div {
    border: 2px solid $primary;

    &.active,
    &:hover{
      background: $primary;
    }
  }
}

.dots-white {
  bottom: -60px;

  > div {
    border: 2px solid $white;

    &.active,
    &:hover{
      background: $white;
    }
  }
}

.owl-classic {

  .owl-prev, .owl-next {
    top: 37%;
  }

  .owl-prev {
    right: calc(100% + 8px);
    left: auto;
  }

  .owl-next {
    left: calc(100% + 8px);
    right: auto;
  }

  @media (min-width: $screen-md-min) {
    .owl-dots {
      display: none !important;
    }
  }
}

.owl-modern {

  .owl-prev, .owl-next {
    top: 44%;
    
    &:hover {
      color: $primary;

      &:after {
        background: $primary;
      }
    }
  }

  .owl-prev {
    right: calc(100% + 8px);
    left: auto;
  }

  .owl-next {
    left: calc(100% + 8px);
    right: auto;
  }

  .box-outline {
    margin-bottom: 10px;
    margin-right: 10px;
  }

  @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {

    .quote-modern {
      .quote-wrap { max-width: 60%; }
    }

    .bg-quote-1, .bg-quote-2 {
      background-position-x: 70%;
    }
  }

  @media (min-width: $screen-md-min) {
    .owl-dots {
      display: none !important;
    }
  }

  @media (min-width: $screen-lg-min) {

    .quote-modern {
      padding-left: 40px;

      .quote-wrap { max-width: 63%; }
    }

    .bg-quote-1, .bg-quote-2 {
      background-position-x: 60%;
    }
  }

  @media (min-width: $screen-xlg-min) {

    .quote-modern {

      .quote-wrap { max-width: 61%; }
    }

    .bg-quote-1, .bg-quote-2 {
      background-position-x: 100%;
    }
  }
}

.owl-creative {
  .box-outline {
    margin-bottom: 10px;
    margin-right: 10px;
  }
}

.partners-carousel {

  .owl-stage-outer {
    padding: 10px 0;
  }

  .owl-stage {
    @include display-flex();
    @include align-items(center);
  }

  .owl-item {

    a {
      will-change: transform;
      transform: translateY(0);

      &:hover {
        transform: translateY(-10px);
      }
    }
  }

  .owl-prev, .owl-next {
    top: 50%;

    &:hover {
      color: $primary;
    }
  }

  .owl-prev {
    left: -30px;
  }

  .owl-next {
    right: -30px;
  }

  @media (min-width: $screen-sm-min) {
    .owl-prev {
      left: -40px;
    }

    .owl-next {
      right: -40px;
    }
  }

  @media (min-width: $screen-md-min) {
    .owl-dots { display: none !important; }
  }

  @media (min-width: $screen-xl-min) {

    .owl-nav { display: none; }
  }
}

.owl-full-width {

  .owl-dots {
    padding-bottom: 30px;
  }
}

.owl-services {
  
  @media (min-width: $screen-xlg-min) {

    .owl-item.active + .owl-item.active {

      .box-services-content {

        &:before {
          position: absolute;
          content: '';
          top: 45px;
          left: 0;
          height: 2px;
          width: 72px;
          background: $white;
          transform: translateX(-50%);
        }
      }
    }

    .owl-dots {
      display: none !important;
    }
  }
}

.owl-info {

  @media (min-width: $screen-lg-min) {
    .owl-dots {
      display: none !important;
    }
  }
}

.owl-team {

  .owl-prev {
    top: 36%;
    right: calc(100% + 16px);
  }

  .owl-next {
    top: 36%;
    left: calc(100% + 16px);
  }
}

.owl-products {

  .owl-prev {
    top: 29%;
    right: calc(100% + 16px);
  }

  .owl-next {
    top: 29%;
    left: calc(100% + 16px);
  }
}

.owl-nav-default .owl-prev,
.owl-nav-default .owl-next {
  white-space: nowrap;

  &:after {
    display: inline-block;
    vertical-align: middle;
    content: '';
    background: $gray;
    transition: inherit;
    width: 0;
    height: 2px;
  }

  &:before {
    display: inline-block;
    vertical-align: middle;
  }

  &:hover {
    &:after {
      width: 53px;
    }
  }
}

.owl-nav-creative {

  .owl-controls {
    position: relative;
    margin-top: 15px;
  }

  .owl-nav {
    > * + * { margin-left: 15px; }
  }

  .owl-next {
    transform: scale(-1, 1);
  }

  .owl-prev {
    transform: none;
  }

  .owl-prev, .owl-next {
    position: static;
    display: inline-block;
    vertical-align: middle;

    &:hover {
      color: $primary;

      &:after {
        background: $primary;
      }
    }
  }

  @media (min-width: $screen-md-min) {
    .owl-dots {
      display: none !important;
    }
  }
}

.owl-nav-right {
  text-align: right;
}